.layout-different-cans {
  .history-slide {
    &__inner {
      grid-template-areas: "text-block";
    }

    &__text-block {
      max-width: 686px;
      z-index: 200;
      position: relative;
    }
  }
}

@include respond-up('large') {
  .layout-different-cans {

    &__special {
      position: absolute;

      &_peas {
        width: 592px;
        height: 592px;
        left: 50%;
        top: -200px;
        z-index: 280;
      }

      &_peas-brain {
        width: 314px;
        height: 319px;
        left: -314px;
        top: calc(117/1046 * (100vh - 83px));
        z-index: 230;
      }

      &_beans {
        width: 513px;
        height: 538px;
        right: -513px;
        top: calc(50% - 150px);
        z-index: 240;
      }

      &_beans-aluminium {
        width: 690px;
        height: 690px;
        left: -690px;
        bottom: -200px;
        z-index: 300;
      }
    }

    .history-slide {
      &__inner {
        position: relative;
        padding-bottom: calc(447/1046 * (100vh - 83px));
        align-content: end;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-different-cans {

    &__special {
      display: none;
    }

    .history-slide {

    }
  }
}