.button {
  padding: 14px 40px;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  border: none;
  background: var(--primary-color);
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: white;

  &_expand, &_wide {
    width: 100%;
  }

  &_gradient {
    font-weight: bold;
    font-size: 25.48px;
    line-height: 114.8%;
    box-shadow: 2px 3px var(--shadow-color);
    background: linear-gradient(90deg, #5DEB51 -8.07%, #1FFDC8 113.25%);
    border-radius: 15px;
  }
}