.layout-three-aluminum-cans {
  align-items: flex-end;

  .history-slide {

    &__text {
      max-width: 489px;
    }
  }
}

@include respond-up('large') {
  .layout-three-aluminum-cans {
    &__bank {
      position: absolute;

      &_big {
        width: 363px;
        height: 443px;
        left: 0;
        bottom: calc(223/1046*(100vh - 83px) + 154px);
        z-index: 100;
      }

      &_medium {
        width: 236px;
        height: 262px;
        left: 468px;
        bottom: calc(223/1046*(100vh - 83px) + 339px);
        filter: blur(4px);
        z-index: 70;
      }

      &_small {
        width: 138px;
        height: 162px;
        left: 793px;
        bottom: calc(223/1046*(100vh - 83px) + 509px);
        filter: blur(6.4px);
        z-index: 30;
      }
    }

    .history-slide {
      &__inner {
        height: 100%;
        align-content: flex-end;
        padding-bottom: calc(223/1046*(100vh - 83px));
      }

      &__text-block {
        flex-direction: row;
      }

      &__text {
        margin-left: 52px;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-three-aluminum-cans {
    &__bank {
      display: none;
    }
  }
}