.offer {
  width: 100%;
  position: relative;

  &__background {

  }

  &__front {
    position: relative;
    z-index: 50;
    height: 100%;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__list {
  }

  &__item {
    position: relative;

  }

  &__title {
    color: #282828;
    font-weight: bold;
  }

  &__subtitle {
    font-weight: 300;
    font-size: 37.4522px;
    line-height: 114.8%;
    color: black;
    max-width: 355px;
  }

  &__buttons {

  }
}

@include respond-up('large') {
  .offer {
    height: calc(var(--vh, 1vh) * 100);

    &__background {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 20;
    }

    &__content {
      height: calc(var(--vh, 1vh) * 100);
      padding-bottom: calc(167 / 960 * 100vh);
      justify-content: flex-end;
    }

    &__button-wrapper {
      display: none;
    }

    &__item {
      pointer-events: none;
      &._active {
        pointer-events: auto;
        .offer {
          &__button-wrapper {
            z-index: 200;
            display: block;
          }
          &__text-block {
            opacity: 1;
            transform: translate(0, 0);
          }

        }
      }
    }


    &__text-block {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: {
        property: opacity, transform;
        timing-function: ease-in-out;
        duration: .6s;
      };


      &._horizontal {
        transform: translate(-50px, 0);
      }

      &._vertical {
        transform: translate(0, 50px);
      }
    }

    &__title {
      max-width: 563px;
      font-size: 87.0506px;
      line-height: 104.8%;
    }


    &__subtitle {
      font-size: 33px;
      line-height: 114.8%;
      max-width: 355px;
      margin-bottom: 10px;
    }


    &__link {
      margin: 23px 0 60px;
      white-space: nowrap;
      position: relative;
      display: inline-block;


    }

    &__link-inner {
      position: relative;
      font-weight: bold;
      font-size: 25.48px;
      line-height: 114.8%;
      color: white;
      padding: 15px 59px 16px 49px;
      background: linear-gradient(90deg, #5DEB51 -8.07%, #1FFDC8 113.25%);
      border-radius: 15px;
      z-index: 20;
    }
  }
}


@include respond('medium') {
  .offer {

    &::before {
      left: 30px;
      right: 30px;
    }

    &__background {
      padding: 33px 30px 0;
    }
  }
}

@include respond-down('medium') {
  .offer {
    margin-bottom: 89px;

    &::before {
      content: '';
      position: absolute;
      top: 33px;
      z-index: 20;
      height: calc(100% - 33px - 84px);
      box-shadow: 4.28px 12.8756px 18.3938px rgba(4, 6, 12, 0.1);
    }

    &__background {
      position: relative;
      z-index: 30;
    }

    &__content {
    }

    &__list {
      width: 100%;
    }

    &__item {
      display: none;

      &._active {
        display: block;
      }
    }

    &__text-block {
      padding: 15px 15px 24px;
    }

    &__title {
      font-size: 20px;
      line-height: 100%;
      margin-bottom: 5px;
    }

    &__subtitle {
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 114.8%;
    }

    &__link {
      width: 100%;
    }

    &__link-inner {
      position: relative;
      color: white;
      padding: 12px 0 13px 0;
      background: linear-gradient(90deg, #5DEB51 -8.07%, #1FFDC8 113.25%);
      border-radius: 0 0 3.43926px 3.43926px;
      display: block;
      z-index: 20;
      font-weight: 600;
      font-size: 15px;
      line-height: 114.8%;
      text-align: center;
    }

    &__buttons {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .offer {
    &::before {
      left: 38px;
      right: 38px;
    }

    &__background {
      padding: 33px 38px 0;
    }
  }
}