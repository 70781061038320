
@include respond-up('large') {
  .hamburger {
    display: none;
  }
}

@include respond-down('medium') {
  .hamburger {
    z-index: 1000;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;


    &__body {
      z-index: 100;
      background: {
        image: url('/static/images/base/subtract.png');
        size: cover;
      };


    }
    &__link {
      max-width: 120px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding: 47px 18px 28px 85px;

      &._opened{
        .hamburger__line{
          &_top{
            width: 32px;
            background-color: black;
            top: calc(50% - 1px);
            transform: rotate(45deg);
            margin-bottom: 0;
          }

          &_middle{
            background-color: black;
            bottom: calc(50% - 1px);
            transform: rotate(-45deg);
            margin-top: -3px;
          }
        }
      }
    }

    &__line {
      display: inline-block;
      background-color: black;

      &_top {
        width: 23px;
        height: 4px;
        border-radius: 1px;
        margin-bottom: 6px;
      }

      &_middle {
        width: 32px;
        height: 4px;
        border-radius: 1px;
      }
    }
  }
}