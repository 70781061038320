.dealer {
  position: relative;

  &__body {
    z-index: 40;
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--blue-hover);
      opacity: 0.1;
      z-index: 3;
    }
  }

  &__inner-image {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;

  }

  &__text-wrapper {
    position: relative;
    z-index: 10;
    background-color: white;
    box-shadow: 0 14px 34px rgba(0, 0, 0, 0.25);
    border-radius: 45px;
  }

  &__title {
    font-weight: bold;
    line-height: 114.8%;
  }

  &__description {
    line-height: 111.2%;

  }

  &__list-title {
    font-weight: bold;
    line-height: 111.2%;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    flex: 0 0 33.3333%;
    color: var(--primary-color);
  }

  &__item-wrapper {
    border-top: 2px solid var(--primary-color);
  }

  &__number {
    font-weight: bold;
    line-height: 111.2%;
    letter-spacing: 0.05em;
  }

  &__text {
    color: black;
    font-size: 22px;
    line-height: 111.2%;
  }

  &__image {
    position: absolute;
  }
}

@include respond-up('large') {
  .dealer {
    min-height: 1144px;
    margin-left: 20px;
    margin-bottom: 50px;

    &__body {
      padding: 129px 0 144px;
      border-radius: 1000px 0 0 1000px;

    }

    &__inner-image {
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: bottom;
      min-width: 3400px;
      height: 100%;
    }

    &__mobile {
      display: none;
    }

    &__text-wrapper {
      padding: 60px 86px;
    }

    &__title {
      font-size: 46.81px;
      margin-bottom: 32px;
    }

    &__description {
      font-size: 22px;
      margin-bottom: 31px;

      strong {
        font-weight: bold;
      }
    }

    &__list-title {
      font-size: 27px;
      margin-bottom: 33px;

    }

    &__list {
      margin: 0 -40px 45px;
    }

    &__item {
      padding: 0 40px;

      &:nth-child(1n+4) {
        margin-top: 40px;
      }

    }

    &__item-wrapper {
      display: flex;
      align-items: baseline;
      padding: 6px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        top: -4px;
        left: 0;
        background-color: var(--primary-color);
        width: 45px;
        height: 6px;
      }
    }

    &__number {
      font-size: 27.99px;
      margin-right: 13px;

    }

    &__image {
      transition: var(--default-transition);
      bottom: -10%;
      left: 5%;
      z-index: 50;

    }
  }
}



@include respond-down('medium') {
  .dealer {
    &__body {
      display: none;

    }

    &__inner {

    }
    &__inner-image {
      display: none;
    }

    &__text-wrapper {

    }

    &__title {

    }

    &__description {

    }

    &__list-wrapper {

    }

    &__list-title {

    }

    &__list {

    }

    &__item {

    }

    &__item-wrapper {

    }

    &__number {

    }

    &__text {

    }

    &__button {

    }

    &__image {
      display: none;
    }
  }
}