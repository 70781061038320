.horizon {
  background-color: white;
  position: relative;

  &__screen {
    background-color: white;
    overflow: hidden;
    z-index: 150;
  }

  &__title {
    font-weight: bold;
  }

  &__list {
    position: relative;
    z-index: 400;
  }

  &__item {

    position: relative;
    z-index: 500;
  }
}

@include respond-up('large') {
  .horizon {

    &__title {
      font-weight: 700;
      font-size: 71px;
      line-height: 87px;
      text-align: center;
    }

    &__screen {
      position: absolute;
      left: 0;
      right: 0;
      height: calc(var(--vh) * 100);
      z-index: 400;
    }

    &__list {
      position: relative;
      display: flex;
    }

    &__item {
      height: calc(var(--vh) * 100);
      width: 100vw;
      flex: 0 0 100vw;
    }

    &__progress {
      position: absolute;
      width: 1136px;
      left: calc(50vw - 568px);
      bottom: calc(118/1046 * (100vh - 83px));
      z-index: 200;
    }
  }
}

@include respond-down('medium') {
  .horizon {
    margin-top: 30px;
    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 88.9%;
      margin-bottom: 10px;
      margin-left: -38px;
      position: relative;
      text-align: left;
      padding: 0 0 0 38px;

      &:before {
        content: '';
        position: absolute;
        left: 30px;
        top: 0;
        width: 3px;
        height: 100%;
        background-color: var(--primary-color);
      }
    }

    &__progress {
      display: none;
    }
  }
}