.ajax-form {

  &__container {

  }

  &__main {

  }

  &__fields {
    margin: -8px 0 42px;
  }

  &__field {
    min-width: 400px;
    padding: 8px 0;
    input[type='text'] {
      border: none;
      background: white;
      box-shadow: inset 6px 4px 14px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
      &::placeholder {
        color: black;
      }
    }

    select {
      border: none;
      background: white;
      box-shadow: inset 6px 4px 14px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
      position: relative;


    }

    textarea {
      min-height: 242px;
      resize: vertical;
      border: none;
      background: white;
      box-shadow: inset 6px 4px 14px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
      &::placeholder {
        color: black;
      }
    }

  }

  &__action {

  }

  &__button {
    position: relative;
    font-weight: bold;
    font-size: 25.48px;
    line-height: 114.8%;
    color: white;
    padding: 15px 59px 16px 49px;
    max-width: 234px;
    background: linear-gradient(90deg, #5DEB51 -8.07%, #1FFDC8 113.25%);
    border-radius: 15px;
    display: block;
    z-index: 20;
  }

  &__success {

  }

  &__success-icon {

  }

  &__success-title {
    font-weight: 500;
    font-size: 36.9194px;
    line-height: 114.8%;
    color: #14F21D;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__title {

  }

  &__success-description {

  }
}