.command {
  display: flex;
  background-color: white;
  &__image-block {

  }

  &__woomen {

  }

  &__big-circle {

  }

  &__text-block {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: bold;
    line-height: 96px;
  }

  &__description {
    line-height: 35px;

  }

  &__button {
    position: relative;
    font-weight: 700;
    font-size: 25.48px;
    line-height: 114.8%;
    color: #fff;
    padding: 15px 59px 16px 49px;
    background: linear-gradient(90deg,#5deb51 -8.07%,#1ffdc8 113.25%);
    border-radius: 15px;
    display: block;
    z-index: 20;
  }

  &__button-wrapper {
    position: relative;
    display: block;

  }

  &__command-leave {
    position: absolute;
    top: 5%;
    left: 0;
  }

  &__command-leave-two {
    position: absolute;
    top: -2%;
    right: 39%;
  }
}

@include respond-up('large') {
  .command {
    padding: 290px 0 360px;
    position: relative;
    overflow: hidden;
    &__image-block {
      flex: 0 0 50%;
    }

    &__woomen {
      position: absolute;
      bottom: -5px;
      z-index: 5;
    }

    &__big-circle {
      position: absolute;
      z-index: 2;
    }

    &__text-block {
      z-index: 10;
      flex: 0 0 50%;
      margin-left: 40px;
    }

    &__title {
      font-size: 79.12px;
      margin-bottom: 23px;
    }

    &__description {
      font-size: 29.12px;
      margin-bottom: 40px;
      max-width: 600px;
    }

    &__button {

    }

    &__button-wrapper {
      max-width: 235px;
    }

    &__small-circle {
      position: absolute;
      bottom: -30%;
      z-index: 7;
      right: 0;
    }
  }
}

@include respond-down('medium') {
  .command {
    margin-bottom: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    &__image-block {
      margin-bottom: 20px;
      min-height: 397px;
      height: calc((350/375)* 100vw);
      background: {
        image: url('/static/images/about/woman-small.png');
        size: cover;
        repeat: no-repeat;
        position: bottom;
      };
    }

    &__woomen,&__big-circle,&__small-circle,&__command-leave,&__command-leave-two {
      display: none;
    }


    &__text-block {
      padding: 0 38px;
    }

    &__title {
      position: relative;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 20px;
      line-height: 88.9%;

      &:before {
        content: '';
        position: absolute;
        left: -10px;
        top: 0;
        width: 3px;
        height: 100%;
        background-color: var(--primary-color);
      }
    }

    &__description {
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      color: #4E4E4E;
      margin-bottom: 30px;
    }

    &__button {

    }

    &__small-circle {

    }
  }
}