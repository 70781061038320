.offer-back {
  height: 100%;
  width: 100%;

  &__item {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: {
      property: opacity;
      duration: .7s;
    };
    z-index: 20;

    &._active {
      z-index: 30;
    }

    &._fade-out {
      z-index: 20;
    }

    &._active, &._fade-out {
      opacity: 1;

      .offer-back {
        &__image {
          transform: translate(0, 0);
        }
      }
    }

    &._from-bottom {
    }
  }

  &__image {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    transition: {
      property: transform;
      duration: .7s;
      timing-function: ease-in-out;
    };
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    };

    &._vertical {
      top: -10%;
      transform: translateY(5%);
    }

    &._diagonal {
      top: -10%;
      right: -10%;
      transform: translate(-5%, 5%);
    }

  }
}

@include respond-up('large') {
  .offer-back {
    &__image{
      &_small {
        display: none;
      }
    }
  }
}

@include respond-down('medium') {
  .offer-back {
    padding-bottom: 100%;
    position: relative;

    &__image{
      &_big {
        display: none;
      }
    }
  }
}