.layout-nature {
  &__back {
    position: absolute;
    z-index: 30;
    top: 0;
    right: 0;
    left: 0;
    background: {
      position: center bottom;
      size: cover;
      repeat: no-repeat;
    };
  }

  .history-slide {
    &__inner {
      grid-template-areas: "text-block";
      position: relative;
      z-index: 70;
    }

    &__text-block {
      background: linear-gradient(134.6deg, #FFFFFF 4.58%, rgba(255, 255, 255, 0.43) 107.44%);
      mix-blend-mode: normal;
      backdrop-filter: blur(37.6168px);
      border-radius: 38.7232px;
    }
  }
}

@include respond-up('large') {
  .layout-nature {
    &__back {
      bottom: calc(229/1046 * (100vh - 83px));
    }

    .history-slide {
      &__inner {
        padding-top: calc(188/1046*(100vh - 83px));
      }
      &__text-block {
        padding: 50px 32px 86px 55px;
        max-width: 857px;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-nature {
    &__back {
      bottom: 0;
    }

    .history-slide {
      padding: 0 20px;

      &__text-block {
        padding: 30px 20px 35px;
      }
    }

  }
}