.layout-hosting-crane {
  .history-slide {

    &__text-block {
      max-width: 489px;
    }
  }
}

@include respond-up('large') {
  .layout-hosting-crane {

    &__crane {
      position: absolute;
      height: calc(854/1046 * (100vh - 83px));
      left: 50%;
      bottom: 0;
      width: calc(1059/1046 * (100vh - 83px));;
    }

    .history-slide {

      &__inner {
        padding-top: calc(80/1046 * (100vh - 83px));
        grid-template-areas: "single-image text-block";
        grid-template-columns: 1fr 1fr;
        grid-gap: 31px;
      }

      &__image-container {
        width: 790px;
        padding-top: 60px;
      }

      &__image-wrapper {
        height: calc(540/1046 * (100vh - 83px));
      }
    }
  }
}

@include respond-down('medium') {
  .layout-hosting-crane {

    &__crane {
      display: none;
    }

    .history-slide {
      &__inner {
        grid-gap: 20px;
        grid-template-areas:
                "text-block"
                "single-image";

      }

      &__image-wrapper {
        width: 100%;
      }

    }
  }
}