@include respond-down('medium') {
  .dealer-mobile {
    &::before {
      position: absolute;
      width: 100%;
      height: 7px;
      background: #F4FCFE;
      content: "";
      display: block;
      top: -45px;
    }
    margin-bottom: 40px;
    &__title {
      font-weight: bold;
      font-size: 17px;
      line-height: 100%;
      position: relative;
      max-width: 180px;
      margin-bottom: 30px;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: -5%;
        width: 3px;
        height: 100%;
        background-color: var(--primary-color);
      }
    }

    &__body {
      display: flex;
      flex-direction: column;

      &_first {

      }
    }

    &__small-title {
      font-weight: bold;
      font-size: 13px;
      line-height: 130%;
      margin-bottom: 15px;
    }

    &__list {
      border: 1px solid #E4F0F3;
      border-radius: 10px 0 0 0;
      margin-bottom: 20px;
    }

    &__item {
      &:nth-child(1) {
        .dealer-mobile__number {
          border-radius: 10px 0 0 0;
        }
      }

      &:last-child {
        .dealer-mobile__number {
          border-radius: 0 0 10px 0;
          margin-bottom: -1px;
        }
      }

    }

    &__wrapper {
      margin-top: -1px;
      border-bottom: 1px solid #E4F0F3;
      display: flex;
      align-items: center;

    }

    &__number {
      margin-right: 8px;
      width: 38px;
      padding: 12px 11px;
      color: var(--shit-green-primary-color);
      font-weight: bold;
      font-size: 13px;
      line-height: 111.2%;
      border: 1px solid #06F02B;
    }

    &__text {
      color: var(--second-grey-color);
      font-size: 13px;
      line-height: 110%;
    }

    &__button {
      margin-bottom: 20px;
      background-color: #2CFA65;
      color: white;
      font-weight: bold;
      font-size: 13px;
      line-height: 110%;
      padding: 15px;
      border-radius: 10px 0;
      text-align: center;
    }

    &__button-form {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      font-weight: bold;
      font-size: 15px;
      line-height: 114.8%;
      border-radius: 10px;
      padding: 10px;
      text-align: center;
      margin-bottom: 40px;

    }
  }
}