.materials {
  position: relative;

  &__shizo-one, &__shizo-five , &__shizo-two, &__shizo-three {
    position: absolute;
  }

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__text-wrapper {

  }

  &__empty {
  }

  &__title {
    font-weight: bold;
    line-height: 87.8%;
  }

  &__catalog-title {
    font-weight: bold;

    line-height: 36px;
  }

  &__catalog-wrapper {
    display: flex;

  }

  &__catalog-logo {
    &:hover {
      .materials {
        &__small-logo {
          opacity: 0;
        }
        &__catalog-block-wrapper {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  &__catalog-block {
    transition: var(--default-transition);
    background-color: white;
    display: block;
  }

  &__catalog-video-inner {
    position: relative;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 24px 44px rgba(19, 42, 125, 0.15);
    transition: var(--default-transition);

  }

  &__catalog-video-link {
    display: inline-block;
  }

  &__catalog-video-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: white;
    box-shadow: 0 1.34224px 8.05342px rgba(21, 20, 71, 0.15);

  }

  &__catalog-download {

  }

  &__svg {

  }

  &__video-wrapper {

  }


  &__item-wrapper {
    background: white;
    border-radius: 18px;
    overflow: hidden;
  }

  &__small-image {
    width: 215px;
  }

  &__small-title {
    font-size: 18px;
    line-height: 112.3%;
    text-align: center;
  }

  &__choose-bloke {
    display: flex;

  }

  &__main-image {

  }

  &__image {

  }
}

._move {
  animation: moveCloud 10s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

._change {
  animation: moveTomat 3s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

._change {
  animation: mainImage 4s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


@keyframes moveCloud {
  from {
    left: 0;
  }
  to {
    left: -10%;
  }
}

@keyframes moveTomat {
  from {
    top: 10%;
    left: -10%;
  }
  to {
    left: 0;
    top: 15%;
  }
}

@keyframes mainImage {
  from {
    margin-bottom: -20%;

  }
  to {
    margin-bottom: 0;
  }
}

@include respond-up('large') {
  .materials {
    padding-bottom: 70px;

    &__vid-list {
      z-index: 20;
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }

    &__catalog-video-inner {
      max-height: 85px;
      overflow: hidden;
    }

    &__item {
      flex: 0 0 50%;
    }

    &__small-logo {
      transition: var(--default-transition);
      padding: 15px 15px;
      object-fit: contain;
    }

    &__shizo-one {
      top: -2%;
      left: 8%;
    }

    &__shizo-two {
      top: 15%;
      left: 0;
    }

    &__shizo-three {
      z-index: 20;
      bottom: 4%;
      left: 5%
    }

    &__shizo-five {
      right: 8%;
      bottom: 5%;
    }

    &__shizo-seven {

    }


    &__body {

    }

    &__text-wrapper {
      position: relative;
      flex: 0 0 31%;
      padding-top: 100px;
      margin-left: var(--large-indent);
    }

    &__title {
      white-space: nowrap; // да знаю я, мне лень в 4 утра разбираться чего его бесоебит
      font-size: 46px;
      margin-bottom: 42px;
    }

    &__catalog-title {
      font-size: 29.36px;
      margin-bottom: 23px;
    }

    &__catalog-wrapper {
      margin: 0 -13px 26px;
    }

    &__catalog-logo {
      padding: 0 13px;
      pointer-events: auto;

    }

    &__catalog-block {
      padding: 0 8px;
    }

    &__catalog-block-wrapper {
      display: flex;
      opacity: 0;
      pointer-events: none;
      transition: var(--default-transition);
      position: absolute;
      top: 50%;
      left: 48%;
      transform: translate(-50%, -50%);
    }

    &__catalog-video-link {
      padding: 0 5px;

    }

    &__rol {
      background: linear-gradient(149.21deg, #F9FFFB -15.8%, #DAFFE6 126.7%);
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      svg {
        margin-left: 2px;
      }

    }

    &__catalog-video-icon {
      width: 50px;
      height: 50px;
      &:first-child {
        svg {
          width: 30px;
          height: 20px;
        }
      }
      &:nth-child(2) {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__catalog-download {

    }

    &__svg {

    }

    &__video-wrapper {

    }

    &__vid-list {
      margin: -13px;
    }

    &__item {
      padding: 13px;
      max-width: 210px;

      &:hover {
        .materials__choose-bloke {
          opacity: 1;
        }
      }

    }

    &__item-wrapper {
      position: relative;

    }

    &__small-image {
    }

    &__small-title {
      padding: 15px 20px;
    }

    &__choose-bloke {
      opacity: 0;
      transition: var(--default-transition);
      margin: 0 -5px;
      position: absolute;
      top: 35%;
      left: 55%;
      transform: translate(-50%, -50%);
    }

    &__main-image {
      flex: 0 0 50%;
    }

    &__image {
      object-fit: contain;
    }
    &__image-mobile {
      display: none;

    }
  }
}
@include respond-down('medium') {
  .materials {

    &::before {
      position: absolute;
      width: 100%;
      height: 7px;
      background: #F4FCFE;
      content: "";
      display: block;
    }

    &__shizo-one {
      display: none;
    }

    &__shizo-two {
      display: none;
    }

    &__shizo-three {
      display: none;
    }

    &__shizo-five {
      display: none;
    }

    &__body {
      flex-direction: column;
    }

    &__text-wrapper {


    }

    &__title {
      padding-top: 55px;
      padding-bottom: 15px;
      font-size: 20px;
      font-weight: 700;
      width: max-content;
      margin: 0 auto;
      position: relative;


      &::before {
        position: absolute;
        background-color: var(--light-green-color);
        content: "";
        left: -8px;
        top: 56px;
        width: 3px;
        height: 14px;
        display: block;
      }
    }

    &__catalog-title {
      padding: 0 38px 15px;
      font-size: 15px;
      font-weight: 400;
    }

    &__catalog-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      padding: 0 38px 15px;
    }

    &__catalog-logo {

    }

    &____catalog-block {

    }

    &__catalog-video-link {
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &__catalog-video-icon {
      display: none;
    }

    &__catalog-download {

    }

    &__svg {

    }

    &__video-wrapper {
      padding: 0 38px;

    }

    &__vid-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 17px;
      padding-bottom: 52px;
    }

    &__item {

    }

    &__item-wrapper {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      position: relative;
    }
    &__small-logo{
      display: block;
      margin: 0 auto;
    }
    &__small-image {
      width: 100%;
    }

    &__small-title {
      padding: 6px 7px 7px 6px;
      font-size: 11px;
      font-weight: 400;
      line-height: 13px;
      text-align: center;

    }

    &__choose-bloke {

    }

    &__rol{
      display: none;
    }

    &__main-image {
      display: none;
    }
    &__main-image-mobile {
      order: 0;
      margin: 0 auto;
      padding-bottom: 3px;
    }
    &__image-mobile {
      width: 100%;
    }

    &__image {

    }
  }
}