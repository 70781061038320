.footer {
  &__absolute-image-one, &__absolute-image-two {
    position: absolute;
  }
}

@include respond-up('large') {
  .footer {
    background-color: white;
    &__list {
      padding: 15px 0 100px;
      display: grid;
      grid-template-columns: 250px 1fr 1fr;
      grid-column-gap: 45px;
    }

    &__nav-button-svg {
      margin-left: 5px;
      svg {
        width: 28px;
        height: 28px;
      }
    }

    &__absolute-image-one, &__absolute-image-two {
      display: none;
    }

    &__nav-button {
      background: linear-gradient(90deg, #5DEB51 -8%, #80FF8C 113%);
      box-shadow: 0 24px 23px -20px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      font-weight: bold;
      font-size: 25px;
      line-height: 115%;
      color: white;
      padding: 14px 27px;
    }

    &__item {
      display: flex;
      align-items: center;
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__nav {
      font-size: 20px;
      line-height: 114.8%;

      &:hover {
        border-bottom: 1px solid black;
        transition: all 0.8s ease-in-out;
      }
    }
  }
}

@include respond-down('medium') {
  .footer {
    background-color: white;

    &__list {
      padding: 15px 0 45px;
      display: flex;
      flex-direction: column;

    }

    &__nav-button-svg {
      display: none;
    }

    &__nav-button {
      position: relative;
      background: linear-gradient(90deg, #5DEB51 -8%, #80FF8C 113%);
      box-shadow: 0 24px 23px -20px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      font-weight: bold;
      font-size: 25px;
      line-height: 115%;
      color: white;
      padding: 14px 27px;
      display: block;
      text-align: center;
      margin-bottom: 27px;
      z-index: 10;
    }

    &__item {
      text-align: center;
      position: relative;

    }

    &__nav {
      font-size: 15px;
      font-weight: 400;
      border-bottom: 1px black solid;
    }

    &__wrapper {
      position: relative;
      z-index: 11;
    }

    &__absolute-image-one {
      top: -33%;
      right: -7%;
      transform: scale(1.3);
      z-index: 11;
    }

    &__absolute-image-two {
      left: -22px;
      bottom: -56px;
      transform: scale(1.3);
    }
  }
}


