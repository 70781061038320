@include respond-up('large') {
  .layout {
    background-color: var(--main-bg);
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;

    &__main {
      margin-top: 60px;
    }

    &__after-content {
      background: {
        size: 100% 75%;
        repeat: no-repeat;
        position: bottom;
      };
    }
  }
}
@include respond-down('medium') {
  .layout {

    &__wrapper {
      overflow: hidden;
    }
    &__after-content {
      background-image: none !important;
    }
  }
}