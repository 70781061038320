.header {
  box-shadow: 0 4px 63px rgba(0, 0, 0, 0.22);
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 200;

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav {
    display: flex;
  }

  &__nav-list {
    display: flex;

  }

  &__nav-item {
    font-size: 17px;
    line-height: 22px
  }
}

@include respond-up('large') {
  .header {
    top: 0;

    &__main {
      padding: 16px 0 10px;
    }

    &__nav-item {
      padding: 0 15px;
      position: relative;

      &:hover {
        .header__nav-wrapper {
          &::before {
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .3s cubic-bezier(.25, .1, .25, 1);
          }
        }
      }
    }

    &__lang-list {
      display: flex;
      flex-direction: column;
    }

    &__langs {
      position: relative;
    }

    &__lang-list {
      position: absolute;
      top: 0;

      &:hover {
        .header__lang-item {
          opacity: 1;
        }
      }
    }

    &__lang-item {
      opacity: 0;
      order: 2;
      transition: var(--default-transition);
      background: {
        size: contain;
        repeat: no-repeat;
      };

      &._active {
        order: 1;
        opacity: 1;
      }
    }

    &__link {
      width: 35px;
      height: 24px;
      display: inline-block;
    }

    &__nav-wrapper {
      border-bottom: 1px solid transparent;
      position: relative;
      transition: all .4s;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding-bottom: 2px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: var(--primary-color);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s ease-out;
      }
    }

    &__mobile {
      display: none;
    }

    &__expand-block {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .header {
    background-color: transparent;
    box-shadow: none;
    bottom: 0;
    right: 0;

    &._opened {
      bottom: 0;
    }

    &__main {
      display: none;
    }

    &__mobile {
      padding: 24px 20px;
      margin: -25px 0;
    }

    &__mobile-logo-img {
      width: 40px;
      height: 26px;
    }

    &__mobile-head-footer {
      margin: 20px 0;
    }

    &__mobile-phone-link {
      display: flex;
      position: relative;
      justify-content: center;

      &:before {
        position: absolute;
        content: '';
        left: 10%;
        top: 0;
        width: 28px;
        height: 28px;
        background: {
          image: url('../images/svg/phone.svg');
          size: cover;
          repeat: no-repeat;
        };
      }
    }

    &__mobile-head-list {
      margin: -2px 0;
      position: relative;
      flex: 0 0 60%;

      &::before {
        content: '';
        position: absolute;
        left: -12%;
        top: 5px;
        background-color: #E8E8E8;
        width: 1px;
        height: 100%;
      }
    }

    &__qwe {
      display: flex;
      align-items: center;
    }

    &__mobile-head-item {
      padding: 2px 0;
    }

    &__mobile-second {
      margin-top: 35px;
    }

    &__mobile-head-body {
      position: relative;
      display: flex;
      padding: 25px 0;
    }

    &__mobile-title {
      flex: 0 0 10%;
      white-space: nowrap;
      width: 70px;
      margin-right: 20px;
      transform: rotate(-90deg);
      font-weight: bold;
      font-size: 23px;
      line-height: 10px;
      display: inline-block;

    }

    &__mobile-head-wrapper {
      color: var(--second-grey-color);
      font-size: 13px;
      line-height: 16px;
    }

    &__mobile-head {
      display: flex;
      justify-content: space-between;
    }

    &__logo {

    }

    &__logo-img {

    }

    &__expand-block {
      transition: var(--default-transition);
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: fixed;
      bottom: 0;
      opacity: 0;
      width: 100%;
      background-color: white;
      border-radius: 20px 0 0 20px;

      &._opened {
        transition: var(--default-transition);
        border-radius: 20px 0 0 20px;
        opacity: 1;
        pointer-events: auto;
        z-index: 100;
        width: 100%;
        background-color: white;
      }
    }

    &__mobile-lang-list {
      display: flex;
      justify-content: center;
      margin: 0 -3px 10px;
    }

    &__mobile-lang-item {
      padding: 0 3px;

      &._active {
        .header__mobile-link {
          padding-bottom: 2px;
          border-bottom: 2px solid var(--primary-color);
        }
      }
    }

    &__mobile-link {
      background: {
        size: contain;
        repeat: no-repeat;
      };
      width: 35px;
      height: 29px;
      display: inline-block;
    }

    &__socials {

    }

    &__nav {
    }

    &__nav-list {

    }

    &__nav-item {

    }

    &__nav-wrapper {

    }

    &__change-lng {

    }
  }
}