.history-slide {

  &__inner {
    display: grid;
    position: relative;
    align-content: start;
  }

  &__text-block {
    grid-area: text-block;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: bold;
    color: #2FFB6B;
  }

  &__text {
    color: black;
  }

  &__image-container {
    grid-area: single-image;
    display: flex;

    &._first {
      grid-area: image-first;
    }

    &._second {
      grid-area: image-second;
    }
  }

  &__image-wrapper {
    &._bordered {
      box-shadow: 0 4.64861px 27.8916px rgba(0, 0, 0, 0.07);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .history-slide {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    padding-top: 83px;

    &__title {
      font-size: 87px;
      line-height: 92px;
    }

    &__text {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@include respond-up('medium') {
  .history-slide {
    &__image-wrapper {
      &._bordered {
        .history-slide__image {
          border: 12px solid white;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .history-slide {
    padding: 40px 35px;
    background-color: white;

    &__title {
      font-size: 65px;
      line-height: 70px;
    }

    &__text {
      font-size: 18px;
      line-height: 23px;
    }
  }
}

@include respond-down('small') {
  .history-slide {
    padding: 23px 20px;
    background-color: white;

    &__title {
      font-size: 55px;
      line-height: 60px;
    }

    &__text {
      font-size: 15px;
      line-height: 19px;
    }

    &__image-wrapper {
      &._bordered {
        .history-slide__image {
          border: 6px solid white;
        }
      }
    }
  }
}