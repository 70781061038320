.layout-cherry {
  .history-slide {
    &__inner {
      grid-template-areas: "text-block";
    }


    &__text {
      max-width: 489px;
    }
  }
}

@include respond-up('large') {
  .layout-cherry {

    &__cherry {
      padding-top: calc(72/1046 * (100vh - 83px));
      width: 269px;
    }

    &__cherry-bank {
      position: absolute;

      &_big {
        left: calc(50% + 50px);
        bottom: calc(259/1046 * (100vh - 83px));
        width: 444px;
        height: 723px;
        z-index: 100;
      }

      &_medium {
        left: calc(50% + 582px);
        bottom: calc(545/1046 * (100vh - 83px));
        width: 186px;
        height: 303px;
        z-index: 70;
      }

      &_small {
        left: calc(50% + 848px);
        bottom: calc(740/1046 * (100vh - 83px));
        width: 53px;
        height: 86px;
        z-index: 30;
      }
    }

    .history-slide {
      &__inner {
        padding-top: calc(148/1046 * (100vh - 83px));
        grid-template-areas: "text-block";
        width: 1105px;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-cherry {

    &__cherry-bank, &__cherry {
      display: none;
    }

  }
}