.about-offer {
  &__body {
    position: relative;
    display: flex;
    flex-direction: column;

  }

  &__sub-description {
    font-size: 26.2392px;
    line-height: 118.2%;
    max-width: 540px;
    margin-bottom: 40px;
  }

  &__image {
    object-fit: cover;
    z-index: 10;
  }

  &__play-button {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__main-title {
    font-weight: bold;
    font-size: 104.027px;
    line-height: 118.2%;
    color: var(--second-primary-color);
    text-shadow: 0 4.41869px 26.5122px rgba(33, 45, 87, 0.14);
    margin-bottom: 20px;
  }

  &__svg {
    position: absolute;
    top: -10px;
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: linear-gradient(171.52deg, #1DFB18 -11.17%, #BFFFC2 143.16%);
    width: 55px;
    height: 55px;
    z-index: 10;
  }

  &__play-wrapper {
    z-index: 10;

  }

  &__text {
    display: inline-block;
    font-size: 20px;
    border-radius: 30px;
    background-color: white;
    line-height: 24px;
    position: relative;
    z-index: 5;
  }

  &__bottom-container {
    background-color: white;
    width: 100%;
    z-index: 7;
    position: relative;
  }

  &__bottom-bg {
    bottom: -20%;
    z-index: 5;
    position: absolute;
  }

  &__bot-inner {
    display: flex;
    justify-content: space-between;
  }

  &__leave-one, &__leave-two {
    position: absolute;
  }

  &__bot-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__bot-title {
    font-weight: bold;
    line-height: 88.9%;
    color: var(--green-color);
  }

  &__bot-subtitle {
    line-height: 34px;
    max-width: 550px;

  }
}

@include respond-up('large') {
  .about-offer {
    &__body {
      min-height: 1000px;
    }

    &__play-wrapper {
      position: absolute;
      top: 15%;
      left: 20%;
    }

    &__image {
      height: calc((800/1500) * 100vw);
      background: {
        image: url('/static/images/about/about-offer.png');
        repeat: no-repeat;
        size: cover;
        position: center;
      };
    }

    &__play-button {
    }

    &__svg {

    }

    &__text {
      white-space: nowrap;
      max-width: 250px;
      padding: 7px 26px 10px 47px;

    }

    &__bottom-container {

    }

    &__leave-one {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &__leave-two {
      position: absolute;
      bottom: -60%;
      right: 0;
    }

    &__bot-wrapper {

    }

    &__bot-title {
      font-size: 56px;
      margin-bottom: 22px;
    }

    &__bot-subtitle {
      font-size: 29px;
    }
  }
}

@include respond-down('medium') {
  .about-offer {
    &__main-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 100%;
      color: black;
      position: relative;
      padding-left: 10px;
      margin-bottom: 10px;

      &:before {
        content: '';
        position: absolute;
        left: -10px;
        top: 0;
        width: 3px;
        height: 100%;
        background-color: var(--primary-color);
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: -10px;
        width: 45px;
        height: 30px;
        background: {
          image: url('/static/images/about/logo-small.png');
          size: cover;
          repeat: no-repeat;
        };
      }
    }

    &__sub-description {
      font-weight: 300;
      font-size: 13px;
      line-height: 114.8%;
      color: #4E4E4E;
    }

    &__play-wrapper {
      padding: 38px 38px;
    }

    &__play-button {
      position: relative;
      &:before {
        content: '';
        width: 84px;
        height: 84px;
        z-index: 80;
        position: absolute;
        left: -35px;
        top: -20px;
        background: {
          image: url('/static/images/about/play-group.png');
          size: cover;
          repeat: no-repeat;
        };
      }
    }

    &__svg {
      display: none;
    }

    &__image {
      height: calc((400/375) * 100vw);
      background: {
        image: url('/static/images/about/about-offer-small.png');
        repeat: no-repeat;
        size: cover;
        position: center;
      };
    }

    &__text {
      width: 100%;
      color: white;
      font-weight: 600;
      font-size: 15px;
      line-height: 114.8%;
      padding: 12px 0;
      text-align: center;
      background: linear-gradient(90deg, #5DEB51 -8.07%, #1FFDC8 113.25%);
      border-radius: 0 0 3.43926px 3.43926px;
    }

    &__bottom-container {

    }

    &__leave-two {
      right: 0;
      bottom: -30%;
    }

    &__leave-one {
      transform: scale(0.5);
      left: -20%;
      bottom: -60%;
    }

    &__bot-inner {
      display: flex;
      flex-direction: column;
    }

    &__bot-title {
      margin-top: 24px;
      font-weight: bold;
      font-size: 20px;
      line-height: 88.9%;
      color: #282828;
      margin-bottom: 5px;
      // в общем, тут по уму надо переменную, далее var(--какая-херня), но у дизайнера все цвета разные и я в рот ебал делать под каждый цвет новую, ебашьте прямо так
    }

    &__bot-subtitle {
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }

    &__bot-wrapper {
      &:nth-child(2) {
        position: relative;
        //z-index: 20;
        //&:before {
        //  z-index: 10;
        //  content: '';
        //  position: absolute;
        //  width: 100%;
        //  height: 100%;
        //  background: {
        //    image: url('/static/images/about/white-small-bg.png');
        //    size: contain;
        //    repeat: no-repeat;
        //  };
        //}
      }
    }

    &__bottom-bg {
      display: none;
    }

    &__bot-title {

    }

    &__bot-subtitle {

    }
  }
}