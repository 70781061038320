.offer-preview {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__item {
    display: none;

    &._active {
      display: block;
    }
  }

  &__link {
    height: 100%;
    width: 100%;
    background: white;
    display: grid;
  }

  &__number {
    grid-area: number;
    font-weight: bold;
    font-size: 40.2594px;
    line-height: 49px;
    color: #E5EFF6;
  }

  &__image-wrapper {
    grid-area: image;
    display: flex;
    justify-content: flex-end;
  }

  &__image {
    background: {
      size: contain;
      repeat: no-repeat;
      position: center;
    };
  }

  &__title {
    grid-area: title;
    font-weight: bold;
    font-size: 18.7941px;
    line-height: 23px;
    color: #92D011;
  }

  &__subtitle {
    grid-area: subtitle;
    font-weight: 300;
    font-size: 14.8759px;
    line-height: 94.8%;
    color: black;
  }
}

@include respond-up('large') {
  .offer-preview {
    grid-gap: 14.82px;

    &__item {
      width: 198.55px;
      height: 154.36px;
    }

    &__link {
      padding: 9px 15px 9px 24px;
      border-radius: 18.956px;
      box-shadow: 0 14.4426px 21.664px -9.02665px rgba(0, 0, 0, 0.25);
      grid-template-areas:
              "number image"
              "title title"
              "subtitle subtitle";
    }

    &__number {
      font-size: 40.2594px;
      line-height: 49px;
    }

    &__title {
      font-size: 18.7941px;
      line-height: 23px;
    }

    &__image {
      height: 64px;
      width: 67px;
    }

  }
}

@include respond-down('medium') {
  .offer-preview {
    padding-top: 20px;
    grid-gap: 10px;
    width: 100%;

    &__item {
      height: 64px;
    }

    &__link {
      padding: 6px 7px 13px 16px;
      border-radius: 12.8837px;
      box-shadow: 0 9.81618px 14.7243px -6.13511px rgba(0, 0, 0, 0.25);
      grid-template-areas:
              "number image"
              "title image";
    }

    &__number {
      font-size: 25px;
      line-height: 30px;
    }

    &__title {
      font-size: 13px;
      line-height: 16px;
    }

    &__subtitle {
      display: none;
    }

    &__image {
      height: 43px;
      width: 45px;
    }

    &__image-wrapper {
      align-items: center;
    }
  }
}