/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$spacer-smaller: 22;
$spacer-small: 22;
$spacer-medium: 40;
$spacer-large: 20;

$grid: (
  small: (
    from: 0,
    to: 767,
    spacer: 38
  ),
  medium: (
    from: 768,
    to: 1199,
    spacer: 30
  ),
  large: (
    from: 1200,
    spacer: 40
  )
);

:root {
  --large-indent: calc((100% - ((var(--max-row-width) * 1px) - (#{$spacer-large} * 2px))) / 2);
  --medium-indent: calc(#{$spacer-medium} * 1px);
  --max-row-width: 1280;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Montserrat", sans-serif;
  --h-font: "Montserrat", sans-serif;
  --offer-slider-bezier: cubic-bezier(.25, .1, .25, 1);
  --default-slider-transition: all .5s var(--offer-slider-bezier);

  --t: transparent;

  --primary-color: #0EF417;
  --second-green-primary-color: #3AE337;
  --shit-green-primary-color: #16FF2E;
  --grey-color: #9A9A9A;
  --second-grey-color: #606060;
  --second-primary-color: #00CB2D;
  --light-green-color: #01FF48;
  --green-color: #10BC0D;
  --main-bg: #F8FAFF;
  --blue-hover: #51D0EC;
  --shadow-color: #43D137;
  --alert-color: #b50000;
  --default-transition: all 0.4s;
  --primary-color-opacity: rgba(1, 199, 103, .85);
}
