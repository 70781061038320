.horizon-progress {
  &__bar {
    width: 100%;
    height: 7px;
    background: white;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.18);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - 11px);
      height: 22px;
      width: 9px;
      background: white;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.18);
      z-index: 40;
    }
  }

  &__values {
    display: flex;
    padding-bottom: 19px;
  }

  &__value {
    flex: 1 1 auto;
    font-size: 19.12px;
    line-height: 22px;

    &:last-child {
      flex: 0 0 0;
      width: 0;
    }
  }

  &__progress {
    background: #99FCAF;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.18);
    height: 100%;
    width: 0;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 26px;
      width: 26px;
      top: calc(50% - 13px);
      background-color: white;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      z-index: 10;
      right: -26px;
    }

    &::before {
      content: '';
      position: absolute;
      height: 16px;
      width: 16px;
      right: -21px;
      top: calc(50% - 8px);
      border-radius: 100px;
      background-color: #1FFF78;
      box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 20;
    }
  }
}