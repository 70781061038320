.directions {
  margin-bottom: 200px;
  position: relative;
  &__banks-item {
    padding: 65px 0;
  }

  &__another {
    position: relative;
  }

  &__back {
    z-index: 1;
    border-radius: 1000px 0 0 1000px;
  }

  &__back-wrapper {
    position: absolute;
    right: 0;
    top: -7%;
  }

  &__back-two {
    z-index: 1;
    position: absolute;
    right: -56px;
    top: -2%;
    border-radius: 1000px 0 0 1000px;

    // у дизайнера проблемы с выгрузкой картинок, костылю как могу
  }

  &__another-wrapper {
    min-height: 520px;
    background-color: white;
    padding: 45px 50px;
    box-shadow: 0px 64px 74px -20px rgba(17, 59, 140, 0.12);
    border-radius: 20px;
    position: relative;
  }

  &__image {
    margin-bottom: 28px;
  }

  &__description {
    max-width: 450px;
    font-size: 26px;
    line-height: 130%;
    margin-bottom: 70px;

    &_bottom {
      margin-bottom: 110px;
    }
  }

  &__button-two {
    display: none;
  }

  &__button-wrapper {
    display: flex;


  }

  &__modal {

  }

  &__button {
    z-index: 100;
    display: flex;
    color: white;
    padding: 14px 40px;
    background: linear-gradient(90deg, #5DEB51 -8.07%, #80FF8C 113.25%);
    box-shadow: 0 24px 23px -20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: bold;
    font-size: 25.48px;
    line-height: 114.8%;
  }

  &__button-active {
    opacity: 1;
    display: block;
    transition: var(--default-transition);

    &._hide {
      opacity: 0;
    }
  }

  &__button-tr {
    color: #26FF63;
    border: 2px solid #26FF63;
    box-sizing: border-box;
    padding: 14px 40px;
    background: transparent;
    border-radius: 10px;
    font-weight: bold;
    font-size: 25.48px;
    line-height: 114.8%;

    &._hide {
      .directions__button-one {
        display: none;
      }
      .directions__button-two {
        display: block;
      }
    }

  }

  &__another-image,&__another-image-two {
    position: absolute;
    right: -10%;
    top: 5%;
    border: 10px solid white;
    border-radius: 20px;
    box-shadow: 0 11px 24px rgba(0, 0, 0, 0.15);

  }


  &__image-title {
    margin-top: 40px;
    font-weight: bold;
    font-size: 36.81px;
    line-height: 114.8%;
    margin-bottom: 41px;
    display: inline-block;
  }

  &__vegetables-wrapper {

  }

  &__leaves-head, &__small-leaves, &__leaves, &__pepper, &__pepper-leaves, &__spoon, &__tomatoes, &__tomatoes-two  {
    position: absolute;
  }

  &__leaves-head {
    right: 25%;
    top: 1%;
  }

  &__leaves {
    left: 10%;
    top: 45%;
    z-index: 20;
  }

  &__pepper {
    top: 5%;
    right: 0;

  }

  &__small-leaves {
    top: 30%;
    right: 0;

  }

  &__pepper-leaves {
    right: 0;
    bottom: 17%;
  }

  &__spoon {

  }

  &__tomatoes {
    top: 0;
    left: 0;

  }

  &__tomatoes-two {
    left: 0;
    bottom: 5%;

  }

  &__head-wrapper {
    position: relative;
  }

  &__title-image {
    position: absolute;
    top: -30px;
    left: 100px;
    z-index: 50;
  }

  &__title {
    font-weight: bold;
    position: relative;
    z-index: 60;
  }

  &__body {

  }

  &__banks-wrapper {

  }

  &__banks-list {
    margin: -65px 0;
  }

  &__wrapper-item {
    position: relative;

    &:before {
      width: 300px;
      height: 306px;
      border-radius: 20px;
      z-index: 2;
      position: absolute;
      content: '';
      bottom: 10%;
      left: 0;
      background-color: white;
    }

    &:after {
      width: 300px;
      height: 180px;
      border-radius: 20px;
      z-index: 1;
      position: absolute;
      content: '';
      bottom: -15px;
      left: 0;
      background: linear-gradient(166.52deg, #7BFF9C 74.92%, #20C94E 100.86%, #2BCD57 100.86%);
      box-shadow: 0 25.0548px 56.3734px -8.35161px rgba(21, 44, 66, 0.15);
    }
  }

  &__wrapper {
    overflow: hidden;
    justify-content: space-between;
    z-index: 10;
    position: relative;
    display: flex;
    background-color: white;
    box-shadow: 0 64px 74px -20px rgba(17, 59, 140, 0.12);
    border-radius: 20px;
  }

  &__banks-text-block {

  }

  &__banks-slider-eko {
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 10;

    &:after {
      //content: '';
      position: absolute;
      width: 300px;
      height: 300px;
      background-image: url('/static/images/banks/back-bank-one.png');
      background: {
        size: cover;
        repeat: no-repeat;
      };
    }
  }

  &__slider-list {
    margin-top: -52px;
    flex: 0 0 50%;
  }


  &__item {
    opacity: 0;
    transition: var(--default-slider-transition) !important;
    position: relative;

    &.tns-slide-active {
      opacity: 1;
    }

    &.fadeIn {
      .directions {
        &__wrapper-item {
          animation: {
            name: default-slider-in-30;
            duration: .5s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__image {
          animation: {
            name: default-slider-in-30;
            duration: .5s;
            delay: .5s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__gost-number {
          position: relative;
          z-index: 10;
          text-align: center;
          font-size: 17px;
          line-height: 130%;
          animation: {
            name: default-slider-in-30;
            duration: .5s;
            delay: .1s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

      }
    }

    &.fadeOut {
      .directions {
        &__wrapper-item {
          animation: {
            name: default-slider-out-30;
            duration: 1s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__image {
          animation: {
            name: default-slider-out-30;
            duration: .5s;
            delay: .1s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__gost-number {
          animation: {
            name: default-slider-out-30;
            duration: .5s;
            delay: .1s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }
      }
    }
  }
  &__gost {

  }

  &__gost-number {

  }

  &__list-eko {

  }

  &__info-point {
    position: relative;

    &::before {
      background-color: var(--primary-color);
      width: 2px;
      height: 2px;
      border-radius: 100%;
      content: '';
      left: -10px;
      top: 0;
    }
  }

  &__bank-image {
    object-fit: cover;
    width: 280px !important;
    height: 320px !important;
    z-index: 10;
    position: relative;
    margin-bottom: 40px;
  }

  &__slider-custommize {
    margin: -5px 0;
  }

  &__slider-custommize-item {
    padding: 5px 0;

    &.tns-nav-active {
      opacity: 1;

      .directions__small-title {
        color: black;
        opacity: 1;
        background-color: white;
        padding: 0 30px 5px;
        font-weight: 500;
        border-radius: 20px;
      }
    }
  }

  &__slider-custommize-wrapper {

  }

  &__small-title {
    white-space: nowrap;
    padding: 0 30px 5px;
    opacity: 0.5;
    font-size: 41px;
    line-height: 130%;
    transition: var(--default-slider-transition);

    &:hover {
      background-color: white;
      border-radius: 20px;
    }
  }
}

@include respond-up('large') {
  .directions {
    background: {
      image: url('/static/images/vegetables/main-bg.png');
      size: cover;
      repeat: no-repeat;
      position: unset;
    };

    &__button-wrapper {
      display: flex;
    }

    &__slider-custommize-wrapper {

    }

    &__button {
      margin-right: 23px;
    }

    &__info-title {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 15px;
      text-align: center;
    }

    &__info-point-title {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px;
    }

    &__info-point {
      margin-bottom: 15px;
      font-size: 18px;
    }

    &__slider-list {

    }

    &__info {
      transition: var(--default-transition);
      opacity: 0;
      padding: 30px;
      background-color: #fff;
      z-index: 100;
      border-radius: 20px;
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 5%;
      right: -10%;
      height: 432px;
      min-height: 436px;
      width: 680px;

      &_mobile {
        display: none;
      }

      &._active {
        opacity: 1;
      }
    }

    &__head-wrapper {
      padding-bottom: 120px;
    }


    &__another-wrapper {
      position: relative;

    }
    &__another-image,&__another-image-two {
      min-height: 436px;
      max-width: 680px;
    }

    &__title {
      font-size: 46px;
      line-height: 100px;
      text-align: center;
    }

    &__wrapper {
      padding: 45px 50px;
      flex-wrap: wrap;
    }

    &__banks-text-block {
      flex: 0 0 38%;
    }

    &__banks-slider-eko {
      flex: 0 0 62%;
    }

    &__list-eko {
      flex: 0 0 50%;

      &_top {
        max-width: 370px;
      }
    }

    &__button-wrapper-mobile {
      display: none;
    }

    &__mobile-icon {
      display: none;
    }
  }
}
@include respond-down('medium') {
  .directions {
    margin-bottom: 50px;
    &__banks-item {
      position: relative;
      padding: 40px 0 50px;

      &:nth-child(1) {
        &:before {
          display: none;
        }
      }


      &::before {
        position: absolute;
        width: 100%;
        height: 7px;
        background: #F4FCFE;
        content: "";
        display: block;
        top: 0;
      }

    }



    &__info {
      transition: var(--default-transition);
      opacity: 0;
      height: 0;
      pointer-events: none;
      display: flex;
      flex-direction: column;

      &._active {
        opacity: 1;
        height: 100%;
        pointer-events: auto;
      }
    }

    &__info-title,&__info-point-title {
      font-weight: 700;
      font-size: 13px;
      line-height: 130%;
      margin-bottom: 10px;
    }


    &__button-tr {
      font-weight: bold;
      font-size: 15px;
      line-height: 114.8%;
      padding: 10px 20px;
      border-radius: 6.71536px;
    }

    &__button-wrapper-mobile {
      display: flex;
      justify-content: space-between;
    }

    &__title-image {
      display: none;
    }

    &__another-image-two,&__another-image {
      right: 0;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
    }


    &__back-wrapper {
      display: none;
    }

    &__another-wrapper {
      min-height: 100%;
      box-shadow: none;
      padding: 0;
    }

    &__image {
      width: 90px;
      object-fit: contain;
      height: 37px;
      margin-bottom: 10px;
      &_eko {
        display: none;
      }
    }

    &__description,&__info-point {
      font-weight: normal;
      font-size: 13px;
      line-height: 16/13*1em;
      margin-bottom: 20px;
      color: #606060;
    }

    &__info-point {
      margin-bottom: 8px;
    }

    &__button-wrapper {
      display: none;
    }

    &__modal {

    }

    &__button {
      font-weight: 600;
      font-size: 15px;
      line-height: 17/15*1em;
      justify-content: center;
      background: linear-gradient(90deg, #5DEB51 -8.07%, #80FF8C 113.25%);
      box-shadow: 0px 16.1169px 15.4453px -13.4307px rgba(0, 0, 0, 0.25);
      border-radius: 6.71536px;
    }

    &__another-image-wrapper {
      width: 100%;
      margin-bottom: 15px;
    }


    &__image-title {
      font-weight: bold;
      font-size: 17px;
      line-height: 130%;
      margin: 0 0 10px;
    }

    &__title {
      font-size: 20px;
      line-height: 20/20*1em;
      padding-left: 9px;
      margin-bottom: 15px;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
        background: var(--light-green-color);
      }

      &:after {
         content: '';
         position: absolute;
         width: 38px;
         height: 38px;
         bottom: 0;
        right: 0;
         background-image: url('/static/images/vegetables/direction-leaves-mobile.png');
         background: {
           size: cover;
           repeat: no-repeat;
         };
       }
    }

    &__banks-list {
      margin: 0;
    }

    &__wrapper {
      flex-direction: column;
      box-shadow: none;
      overflow: unset;
    }

    &__banks-slider-eko {
      display: flex;
      justify-content: flex-start;

      &:after {
        display: none;
      }
    }

    &__slider-list {
      flex: 0 0 75%;
    }

    &__gost-number {
      display: none;
    }

    &__list-eko {
      flex: 0 0 25%;
      order: -1;
      z-index: 10;
      margin-right: 10px;
    }

    &__slider-custommize-item {
      width: 100%;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      background: #FFFFFF;
      padding: 0;
      margin: 5px 0;
      max-width: 100px;
    }

    &__small-title {
      display: none;
    }

    &__mobile-icon {
      display: flex;
      position: relative;
      padding-bottom: calc(40/74*100%);
    }

    &__mobile-image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 3px 0;
    }

    &__leaves-head, &__small-leaves, &__leaves, &__pepper, &__pepper-leaves, &__spoon, &__tomatoes, &__tomatoes-two  {
      display: none;
    }

    &__wrapper-item {
      margin-top: 15px;

      &:before {
        display: none;
      }

      &:after {
        background: linear-gradient(170.92deg, rgba(93, 235, 81, 0.3) -26.04%, rgba(31, 253, 200, 0.3) 143.46%);
        border-radius: 12px;
        bottom: 20px;
        height: calc(220/280*100%);
      }
    }

    &__bank-image {
      margin-bottom: 0;
      width: auto !important;
      height: auto !important;
      min-height: 278px;
      min-width: 232px;
      padding-top: 0;
    }
  }
}