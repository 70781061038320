.layout-four-glass-jars {
  align-items: flex-end;

  .history-slide {
    &__inner {
    }

    &__text-block {
      justify-content: center;
    }

    &__text {
      max-width: 435px;
    }
  }
}

@include respond-up('large') {
  .layout-four-glass-jars {

    &__glass-jar {
      position: absolute;
      object-fit: contain;

      &_top {
        width: 266px;
        height: 287px;
        top: -83px;
        left: calc(50% - 200px);
      }

      &_left {
        width: 118px;
        height: 123px;
        left: 111px;
        bottom: 198px;
      }

      &_right {
        width: 955px;
        height: 994px;
        top: -726px;
        right: -347px;
      }

      &_bottom {
        width: 371px;
        height: 396px;
        bottom: -200px;
        left: 50%;
      }
    }


    .history-slide {
      &__inner {
        grid-gap: 56px;
        padding: calc(30/1046 * (100vh - 83px)) 0 calc(305/1046 * (100vh - 83px));
      }
      &__image-wrapper {
        height: calc(553/1046*(100vh - 83px));
        width: calc(843/1046*(100vh - 83px));
      }
    }
  }
}

@include respond-up('medium') {
  .layout-four-glass-jars {
    .history-slide {
      &__inner {
        grid-template-areas: "single-image text-block";
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-four-glass-jars {
    &__glass-jar {
      display: none;
    }

    .history-slide {
      &__inner {
        grid-gap: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .layout-four-glass-jars {
    .history-slide {
      &__inner {
        grid-template-areas:
                "text-block"
                "single-image";
      }
    }
  }
}