.layout-peans-and-beans {
  .history-slide {
    &__inner {
      grid-template-areas:
              "text-block"
              "image-first";
    }

    &__text-block {
      position: relative;
    }

    &__title {
      position: absolute;
      transform: rotate(-90deg);
      transform-origin: right top;
    }

    &__text {
      max-width: 510px;
    }

    &__image-container {
      &._first {
        position: relative;
      }

      &._second {
        position: absolute;
      }
    }
  }
}

@include respond-up('large') {
  .layout-peans-and-beans {

    &__special {
      position: absolute;
      z-index: 50;

      &_peas {
        width: 644px;
        height: 567px;
        left: -187px;
        top: calc(50% - 270px);
      }

      &_beans {
        width: 475px;
        height: 456px;
        right: -153px;
        bottom: 0;
      }
    }

    .history-slide {
      &__inner {
        z-index: 100;
        position: relative;
        padding: calc(90 / 1046 * (100vh - 83px)) 0 calc(233 / 1046 * (100vh - 83px));
        grid-auto-rows: auto 1fr;
      }

      &__text-block {
        padding-bottom: 60px;
      }

      &__title {
        left: -320px;
      }

      &__image-container {
        &._first {
          width: 843px;
          height: 100%
        }

        &._second {
          right: -332px;
          width: 625px;
          height: calc(413/1046*(100vh - 83px));
          top: calc(90 / 1046 * (100vh - 83px));;
        }
      }

      &__image-wrapper {
        &._first {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
  }
}


@include respond('medium') {
  .layout-peans-and-beans {
    .history-slide {
      &__text-block {
        padding-left: 79px;
        min-height: 150px;
      }

      &__title {
        left: -154px;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-peans-and-beans {
    &__special {
      display: none;
    }
    .history-slide {
      &__inner {
      }

      &__image-container {
        &._first {
          padding-top: calc(120 / 375 * 100vw);
          padding-right: calc(30 / 375 * 100vw);
        }
        &._second {
          right: 0;
          width: 65vw;
          bottom: calc(140 / 375 * 100vw);
          height: calc(170 / 375 * 100vw);
        }
      }

      &__image-wrapper {
        &._first {
          height: calc(206 / 375 * 100vw);
        }
      }
    }
  }
}

@include respond-down('small') {
  .layout-peans-and-beans {
    .history-slide {
      &__text-block {
        padding-left: 55px;
        min-height: 125px;
      }

      &__title {
        left: -133px;
      }
    }
  }
}