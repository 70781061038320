.layout-test-tubes {
  .history-slide {
    &__text-block {
      justify-content: center;
    }

    &__text {
      max-width: 489px;
    }



  }
}

@include respond-up('large') {
  .layout-test-tubes {
    padding: calc(104/1046 * (100vh - 83px) + 83px) 0 calc(269/1046 * (100vh - 83px));

    &__tubes {
      grid-area: tubes;
      width: 342px;
    }

    .history-slide {
      &__inner {
        grid-template-areas:
                "tubes image-first text-block"
                "tubes image-first image-second";
        grid-gap: 32px 24px;
        grid-template-rows: auto 1fr;
        align-items: end;
      }

      &__image-container {
        &._first {
          grid-area: image-first;
          align-items: flex-end;
          justify-content: flex-end;
          height: 100%;
        }

        &._second {
          height: 100%;
          width: 552px;
        }
      }

      &__image-wrapper {
        position: relative;

        &._first {
          height: 100%;
          width: 486px;
        }

        &._second {
          height: 100%;
          width: 552px;
        }
      }

      &__image {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-test-tubes {
    &__tubes {
      display: none;
    }
    .history-slide {
      &__inner {
        grid-template-areas:
                "image-first text-block"
                "image-first image-second";
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
      }
    }
  }
}