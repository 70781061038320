.layout-info-cans {
  &__info-wrapper {
    grid-area: info;
  }

  .history-slide {
    &__inner {
      grid-gap: 20px;
    }
    &__text-block {
      max-width: 489px;
    }
  }
}

@include respond-up('large') {
  .layout-info-cans {

    &__pepper {
      position: absolute;
      object-fit: contain;

      &_big {
        z-index: 100;
        width: 362px;
        height: 353px;
        left: 70px;
        bottom: 0;
      }

      &_small {
        z-index: 20;
        width: 144px;
        height: 141px;
        top: 141px;
        left: calc(50% - 110px);
      }
    }


    &__info-wrapper {
      position: relative;
    }

    &__info {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: contain;
      object-position: left bottom;
      height: 100%;
    }
    .history-slide {
      &__inner {
        position: relative;
        z-index: 50;
        width: 1130px;
        grid-template-areas: "text-block info";
        grid-template-columns: auto 1fr;
        padding: calc(63 / 1046 * (100vh - 83px)) 0 calc(190 / 1046 * (100vh - 83px));
        grid-template-rows: 1fr;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-info-cans {
    &__pepper {
      display: none;
    }

    .history-slide {
      &__inner {
        grid-template-areas:
                "text-block"
                "info";
      }
    }
  }
}