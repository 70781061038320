.project {
  background: {
    size: cover;
    repeat: no-repeat;
    position: center;
  };
  &__title {
    font-weight: bold;
    font-size: 71px;
    line-height: 87px;
    text-align: center;
  }

  &__body {
    position: relative;
    display: flex;
    background-color: white;
    box-shadow: 0px 34px 34px -10px rgba(17, 59, 140, 0.2);
    border-radius: 20px;
  }

  &__text-container {

  }

  &__slider-list {

  }

  &__item {
    opacity: 0;
    transition: var(--default-slider-transition) !important;
    position: relative;

    &.tns-slide-active {
      opacity: 1;
    }

    &.fadeIn {
      .project {
        &__wrapper {
          animation: {
            name: default-slider-in-30;
            duration: .5s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__text-title {
          animation: {
            name: default-slider-in-30;
            duration: .5s;
            delay: .4s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__text-description {
          animation: {
            name: default-slider-in-30;
            duration: .5s;
            delay: .4s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__image {
          animation: {
            name: default-slider-in-30;
            duration: .5s;
            delay: .5s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }
      }
    }

    &.fadeOut {
      .project {
        &__wrapper {
          animation: {
            name: default-slider-out-30;
            duration: .5s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__text-title {
          animation: {
            name: default-slider-out-30;
            duration: .5s;
            delay: .1s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__text-description {
          animation: {
            name: default-slider-out-30;
            duration: .5s;
            delay: .1s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }

        &__image {
          animation: {
            name: default-slider-out-30;
            duration: .5s;
            fill-mode: forwards;
            timing-function: var(--offer-slider-bezier);
          };
        }
      }
    }

  }

  &__wrapper {
    display: flex;
  }

  &__text-title {
    font-weight: bold;
    font-size: 47.5396px;
    line-height: 58px;
    margin-bottom: 16px;
    color: var(--second-primary-color);
    max-width: 449px;
  }

  &__text-description {
    font-weight: 300;
    font-size: 20px;
    line-height: 110%;
    max-width: 450px;
  }

  &__image {


  }

  &__image-container {

  }

  &__slider-custommize {
    display: flex;

  }

  &__slider-custommize-item {
    flex: 0 0 33.333%;


    &.tns-nav-active {
      display: block;

      .project__small-image {
        border: 4px solid var(--second-primary-color);
        border-radius: 10px;
        display: block;
      }
    }
  }

  &__small-image {
    object-fit: cover;
    width: 90px;
    height: 70px;
    border-radius: 10px;
  }
}

@include respond-up('large') {
  .project {
    padding-bottom: 200px;
    background: {
      image: url('/static/images/projects/bg.png');
      size: cover;
      repeat: no-repeat;
      position: center;
    };
    &__title {
      padding: 80px 0 120px;
    }

    &__body {
    }

    &__text-inner {
      padding: 30px 70px;
    }

    &__slider-list {

    }

    &__item {
    }

    &__wrapper {

    }

    &__text-title {

    }

    &__text-description {
      margin-bottom: 60px;
    }

    &__image {

    }

    &__image-container {

    }

    &__slider-custommize {
      margin: -12px;
      width: 440px;
      flex-wrap: wrap;
      position: absolute;
      top: 63%;
      left: 6%;
    }

    &__slider-custommize-item {
      padding: 12px;

    }

    &__small-image {

    }
  }
}
@include respond-down('medium') {
  .project {
    min-height: 630px;
    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 88.9%;
      margin-bottom: 25px;
      position: relative;
      text-align: left;
      padding: 0 38px;

      &:before {
        content: '';
        position: absolute;
        left: 30px;
        top: 0;
        width: 3px;
        height: 100%;
        background-color: var(--primary-color);
      }
    }

    &__body {
      box-shadow: none;
      background-color: transparent;

    }

    &__text-container {

    }

    &__slider-list {

    }

    &__item {
      position: relative;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__text-title {
      font-weight: bold;
      font-size: 13px;
      line-height: 120%;
      margin-bottom: 20px;
      color: black;
    }

    &__text-description {
      margin-bottom: 33px;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px
    }

    &__image {
      position: absolute;
      right: -30%;
      top: 100%;

    }

    &__image-container {

    }

    &__slider-custommize {
      position: relative;
      flex-direction: column;
      margin: -5px 0;
    }

    &__slider-custommize-item {
      margin: 5px 0;

      &:last-child {
        display: none;
      }
    }

    &__small-image {
      width: 64px;
      height: 64px;
    }
  }
}