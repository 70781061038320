.layout-scattered-photos {
  .history-slide {
    &__text-block {
      max-width: 489px;
    }
  }
}

@include respond-up('large') {
  .layout-scattered-photos {
    .history-slide {
      &__inner {
        padding-top: calc(107/1046 * (100vh - 83px));
        grid-template-areas: "text-block";
        width: 1062px;
      }

      &__image-container {
        position: absolute;
        transform-origin: left top;

        &._first {
          top: 0;
          left: calc(50% + 26px);
          width: calc(768/1046 * (100vh - 83px));
          height: calc(511/1046 * (100vh - 83px));
          transform: rotate(7.5deg);
        }

        &._second {
          top: calc(107/1046 * (100vh - 83px) + 325px);
          left: calc(50% - 133px);
          width: calc(690/1046 * (100vh - 83px));
          height: calc(460/1046 * (100vh - 83px));
          transform: rotate(-7deg);
        }

        &._third {
          top: calc(107/1046 * (100vh - 83px) + 343px);
          left: -33px;
          width: calc(658/1046 * (100vh - 83px));
          height: calc(438/1046 * (100vh - 83px));
          transform: rotate(13.3deg);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .layout-scattered-photos {
    .history-slide {
      &__inner {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
                "text-block text-block"
                "image-first image-first"
                "image-second image-third";
      }

      &__image-container {
        &._third {
          grid-area: image-third;
        }
      }

      &__image-wrapper {
        width: 100%;
      }
    }


  }
}