.socials {
  &__list {

  }

  &__item {

  }

  &__wrapper {

  }

  &__img {

  }
}
@include respond-up('large') {

}
@include respond-down('medium'){

  .socials {
    &__list {

    }

    &__item {
      display: none;
    }

    &__wrapper {

    }

    &__img {

    }
  }

}