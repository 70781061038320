.contact {
  background-color: white;
  position: relative;

  &__absolute-image-one,
  &__absolute-image-two ,
  &__absolute-image-three,
  &__absolute-image-four,
  &__absolute-image-five,
  &__absolute-image-six,
  &__absolute-image-seven,
  &__absolute-image-eight,
  &__absolute-image-nine{
    position: absolute;
  }

  &__req {

  }

  &__inner {
    flex-direction: column;
    display: flex;
    flex: 0 0 40%;
  }

  &__title {
    font-weight: bold;
    line-height: 118.2%;
  }


  &__phone, &__email {
    font-weight: 600;
    line-height: 118.2%;
  }

  &__company-name {
    display: none;
  }

  &__address, &__index {
    font-weight: normal;
    line-height: 118.2%;
  }
}

@include respond-up('large') {
  .contact {
    padding-bottom: 170px;
    position: relative;
    z-index: 10;

    &__image-wrapper {
      filter: drop-shadow(0px 3.75017px 22.501px rgba(0, 0, 0, 0.15));
      border: 19px solid white;
      box-shadow: inset 18px 22px 22px rgba(0, 0, 0, 0.25);
      border-radius: 56px;
      flex: 0 0 632px;
      min-height: 520px;
      background: {
        repeat: no-repeat;
        position: center;
      };

    }

    &__absolute-image-one {
      top: -13%;
      left: -9%;
    }

    &__absolute-image-two {
      bottom: 0;
      left: 0;
    }

    &__absolute-image-three {
      right: 0;
      bottom: 0;
    }

    &__absolute-image-four {
      bottom: 3%;
      right: 6%;
    }

    &__absolute-image-five {
      bottom: 16px;
      left: 40%;
      z-index: 1;
    }

    &__req {
      z-index: 17;
      position: relative;
      justify-content: space-between;
      display: flex;
      filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.15));
      box-shadow: inset 18px 22px -10px rgba(0, 0, 0, 0.25);
      border-radius: 28px;
      background: {
        size: cover;
        repeat: no-repeat;
        position: center;
      };
      margin: 0 -100px;
      padding: 97px 128px 123px 119px;
    }

    &__email {
      margin-bottom: 33px;
    }

    &__title {
      font-size: 65px;
      margin-bottom: 57px;
    }

    &__phone, &__email {
      font-weight: 600;
      font-size: 37.9845px;
    }

    &__address, &__index {
      font-size: 37.9845px;
    }

    &__bad-way {
      position: absolute;
      width: 100%;
      height: 30%;
      z-index: 15;
      top: -30%;
      background-size: cover;
      background-repeat: no-repeat;
      background: {
        size: cover;
        repeat: no-repeat;
      };
    }
  }
}

@include respond('small') {
  .contact {

    &::before {
      position: absolute;
      width: 100%;
      height: 7px;
      background: #F4FCFE;
      content: "";
      display: block;
    }

    &__inner{
      max-width: 60%;
    }
    &__image-veg {

    }

    &__absolute-image-one, &__absolute-image-two, &__absolute-image-three, &__absolute-image-four, &__absolute-image-five {
      display: none;
    }

    &__absolute-image-six {
      left: 45%;
      top: -15%;
      transform: scale(-0.8, 0.8);
      z-index: 1;
    }

    &__absolute-image-seven {
        top: -8%;
        z-index: 1;
      }

    &__req {
     background-image: none !important;
    }

    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      padding-top: 60px;
      padding-bottom: 20px;

      &::before {
        position: absolute;
        background-color: var(--light-green-color);
        content: "";
        left: 29px;
        top: 65px;
        width: 3px;
        height: 14px;
        display: block;
      }
    }

    &__phone, &__email {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      padding-bottom: 14px;
      z-index: 1;
    }

    &__address{
      font-size: 15px;
      font-weight: 400;
      padding-bottom: 15px;
      z-index: 1;
    }

    &__index{
      font-size: 15px;
      font-weight: 400;
      padding-top: 16px;
    }

    &__image-wrapper {
      display: none;

    }

    &__main-image {

    }
  }
}