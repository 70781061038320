.layout-three-peppers {
  .history-slide {
    &__inner {
    }

    &__text-block {
      justify-content: center;
    }

    &__text {
      max-width: 616px;
    }
  }
}

@include respond-up('large') {
  .layout-three-peppers {
    &__pepper {
      position: absolute;
      object-fit: contain;

      &_big {
        width: 280px;
        height: 275px;
        left: 100%;
        bottom: calc(178/1046 * (100vh - 83px));
      }

      &_medium {
        width: 175px;
        height: 165px;
        left: calc(161/1046 * (100vh - 83px));
        top: calc(66/1046 * (100vh - 83px));
      }

      &_small {
        width: 73px;
        height: 73px;
        bottom: calc(35/1046 * (100vh - 83px));
        left: calc(50vw - 205px);
      }
    }

    .history-slide {
      &__inner {
        padding: calc(66/1046 * (100vh - 83px)) 0 calc(221/1046 * (100vh - 83px));
        grid-gap: 29px 24px;
        grid-template-areas:
                "image-first image-second"
                "image-first text-block";
        grid-template-rows: auto 1fr;

      }

      &__image-container {
        &._first {
          grid-area: image-first;
          align-items: flex-end;
          justify-content: flex-end;
        }
      }

      &__image-wrapper {
        &._first {
          height: calc(551/1046 * (100vh - 83px));
          width: calc(430/1046 * (100vh - 83px));
        }

        &._second {
          height: calc(432/1046 * (100vh - 83px));
          width: 616px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .layout-three-peppers {
    &__pepper {
      display: none;
    }

    .history-slide {
      &__inner {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
                "image-second image-second"
                "image-first text-block";
      }
      &__image-container {
        &._second {
          justify-content: flex-end;
        }
      }
      &__image-wrapper {
        &._first {
          height: 100%;
          width: 100%;
        }

        &._second {
          height: calc(177/375 * (100vw - 46px));
          width: calc(300/375 * (100vw - 46px));
        }
      }
    }
  }
}