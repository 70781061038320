h1, h2, h3, h4, h5, h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
}

h1 {
  --h-size: 30px;
}

h2 {
  --h-size: 28px;
}

h3 {
  --h-size: 26px;
}

h4 {
  --h-size: 24px;
}

h5 {
  --h-size: 22px;
}

h6 {
  --h-size: 20px;
}