.layout-rotate-tomato {
  .history-slide {
    &__inner {
      z-index: 300;
    }


    &__text {
      max-width: 489px;
    }

    &__image-container {
      &._absolute {
        position: absolute;
        left: 0;
        z-index: 100;
      }
      &._second {
      }
    }

    &__image-wrapper {
      height: 100%;
      width: 100%;
    }

  }
}

@include respond-up('large') {
  .layout-rotate-tomato {
    &__tomato {
      position: absolute;
      right: 30px;
      width: calc(790/1927 * 100vw);
      height: calc(829/1927 * 100vw);
      top: -25vw;
    }

    .history-slide {
      &__inner {
        padding: calc(199/1046 * (100vh - 83px)) 0 calc(288/1046 * (100vh - 83px));
        grid-template-areas: "image-second text-block";
        grid-gap: 54px;
      }

      &__image-container {
        &._absolute {
          top: 83px;
          height: calc(542/1046 * (100vh - 83px));
          width: calc(100vw / 2 - 204px);
        }
        &._second {
          width: 721px;
          height: calc(558/1046 * (100vh - 83px));
        }
      }

      &__text-block {
        justify-content: flex-end;
        padding-bottom: 10px;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-rotate-tomato {
    &__tomato {
      display: none;
    }

    .history-slide {
      &__inner {
        grid-template-areas:
                "image-second"
                "text-block";
        grid-gap: 20px;
      }

      &__image-container {
        &._absolute {
          top: 0;
          height: 50vw;
          width: 60vw;
        }

        &._second {
          padding-left: 10%;
          padding-top: 10vw;
        }
      }
    }
  }
}