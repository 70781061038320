.offer-special {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__object {
    position: absolute;
    transition: {
      property: transform;
      timing-function: ease-in-out;
    };

    &_pepper {
      transition-duration: .8s;
      transform: translate(80px, 35%);
      left: -74px;
      bottom: 16%;
    }

    &_tomato {
      transition-duration: .8s;
      transform: translate(50px, -35%);
      right: 30px;
      top: 10%;
    }

    &_oldman {
      transition-duration: .8s;
      transform: translateY(10%);
    }
  }
}

.offer-back__item {
  &._active {
    .offer-special {
      &__object {
        transform: translate(0, 0);
      }
    }
  }

  &._fade-out {
    .offer-special {
      &__object {
        transform: translate(0, 0);
      }
    }
  }
}

@include respond-up('large') {
  .offer-special {
    &__object {
      &_oldman {
        bottom: -10px;
        top: 60px;
        right: 10%;
      }
    }
  }
}

@include respond-down('medium') {
  .offer-special {
    &__object {
      &_pepper, &_tomato {
        display: none;
      }
      &_oldman {
        bottom: -13%;
        top: 5%;

        .offer-special__img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center bottom;
        }
      }
    }
  }
}
